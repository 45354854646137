<template>
  <div class="class">
    <div class="top">
       <el-button class="el-but2" type="primary" @click="go()" >返回</el-button>
      <span style="padding-left:10px">{{this.$route.query.className}}</span>
      <el-button class="el-but2"   type="primary" @click="addBt()" >新增课程管理</el-button>
      <el-upload ref="upload" style="padding-left: 20px;"
                 accept=".xls,.xlsx,.pdf,.doc,.docx,.pptx,.ppt"
                 action="#"
                 :auto-upload="true"
                 :multiple="false"
                 :file-list="fileList"
                 :before-upload="beforeUpload"
                 :http-request="uploadHttpRequest"
                 :on-remove="fileRemove"
                 :on-change="fileChange"
                 :show-file-list="false"
                  >
        <el-button size="small" type="success">导入课程模板</el-button>
      </el-upload>
      <el-button class="el-but2" type="warning" @click="expot()" >导出课程模板</el-button>
    </div>
    <el-row class="bg">
    <el-table
      :data="curriculumData">
      <el-table-column
        label="课程日期"
        width="82px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.courseDate}}{{scope.row.late}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="开始时间"
        width="68px">
        <template slot-scope="scope">
          <span>{{scope.row.startTime}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="结束时间"
        width="68px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.endTime}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="星期"
        width="56px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.week}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="课程内容"
        align="center">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.content}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="讲师"
        width="90px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.lecturer}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="职务"
        width="100px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.duty}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="单位"
        width="160px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.unit}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="教室"
        width="90px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.classroom}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="课程打卡"
        width="68px">
        <template slot-scope="scope">
          <span class="spani" v-if="scope.row.type == '0'">需要打卡</span>
          <span class="spani" v-else>不用打卡</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="300px">
        <template slot-scope="scope">
          <el-button size="mini" type="danger" @click="removeData(scope.row)">删除</el-button>
          <el-button size="mini" type="primary" @click="editData(scope.row)">修改</el-button>
          <el-button size="mini" type="success" @click="expotData(scope.row)">导出课程评估表</el-button>
        </template>
      </el-table-column>
      </el-table>
    </el-row>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-size="pageSize"
      layout="total,sizes,  prev, pager, next, jumper"
      :total="total"
      class="pag"
    >
    </el-pagination>
<!-- Add课程管理弹窗 -->
    <el-dialog
      title="课程管理新增/编辑"
      :visible.sync="curriculumDialogVisible"
      width="70%"
      :show-close="showclose"
      :close-on-click-modal="showclose"
      center>
      <el-form
        :model="curriculumFrom"
        label-position="right"
        label-width="120px"
        :rules="rules3"
        ref="curriculumFrom" >
          <el-row>
           <el-col :span="12"  style="overflow: hidden;">
            <el-form-item label="课程日期"  prop="value2">
               <el-date-picker
                  v-model="curriculumFrom.value2"
                  type="date"
                   @input="datetimeChange"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期" style="width:100%;overflow: hidden;">
                </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开始结束时间"  prop="value3">
            <el-time-picker
                  is-range
                  arrow-control
                  v-model="curriculumFrom.value3"
                   value-format=" HH:mm:ss"
                  range-separator="至"
                  start-placeholder="课程开始时间"
                  end-placeholder="课程结束时间"
                  placeholder="选择时间范围"  style="width:100%">
              </el-time-picker>
            </el-form-item>
          </el-col>
          </el-row>
          <el-row>
          <el-col :span="12">
              <el-form-item label="课程内容"  prop="content">
              <el-input  style="width:100%" v-model="curriculumFrom.content"></el-input>
          </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="讲师"  prop="lecturer">
              <el-input  style="width:100%" v-model="curriculumFrom.lecturer"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职务"  prop="duty">
              <el-input v-model="curriculumFrom.duty" style="width: 100%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位"  prop="unit">
              <el-input v-model="curriculumFrom.unit" style="width:100%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="教室"  prop="classroom">
              <el-input v-model="curriculumFrom.classroom" style="width: 100%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="评估"  prop="isAssess">
               <el-select style="width: 100%"  placeholder="请选择" v-model="curriculumFrom.isAssess" filterable clearable>
                <el-option label="需要评估" :value="0"/>
                <el-option label="不需要评估" :value="1"/>
                </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="课程打卡"  prop="type">
               <el-select style="width: 100%"  placeholder="请选择" v-model="curriculumFrom.type" @change="changeType(curriculumFrom.type)" filterable clearable>
                <el-option v-for="(item,index) in typeData"
                          :key="index"
                          :label="item.title"
                          :value="Number(item.value)"/>
                </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上课地点" prop="pointValue">
              <el-select style="width: 100%" v-model="curriculumFrom.pointValue" @change="slectChange">
                <el-option v-for="i in authenticateList" :value="i.id" :label="i.name" :key="i.id" ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="needShow">
            <el-form-item label="正常打卡时间：上课前" prop="signStart" label-width="200px">
              <el-input v-model="curriculumFrom.signStart" style="width:50px"></el-input>
              分钟
            </el-form-item>
            <el-form-item label="迟到打卡时间：上课后" prop="late" label-width="200px">
              <el-input v-model="curriculumFrom.late" style="width:50px"></el-input>
              分钟内
            </el-form-item>
          </el-col>
          <el-col :span="24" v-show="false">
            <el-form-item label="经纬度">
              <el-input v-model.number="curriculumFrom.longitude" style="width: 150px"></el-input>-
              <el-input v-model.number="curriculumFrom.latitude" style="width: 150px"></el-input>
              <span> 点击地图获取经纬度</span>
            </el-form-item>
            <el-form-item label="">
               <div>
                <TMap ref="child" v-bind:form="form" @map-confirm="confirmLocation" @getPlace="getPlace"></TMap>
               </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button @click="reset">取 消</el-button>
          <el-button type="primary" @click="Add('curriculumFrom')">提 交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import { curriculumList, getConfig, curriculumAdd, removecurriculum, editcurriculum, getcurriculum, getDownExcel, downLoadTemplate, importTemplateExcel } from '@/api/user.js'
  import { getAuthenticateList } from '@/api/training'
  import { mapMutations } from 'vuex'
  import TMap from './TMap.vue'
  export default {
    name: 'Classinformation',
     components: {
      TMap
    },
    data () { // 数据模型
     const checkDate = (rule, value, callback) => { // 日期
        if (!value) {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
     const checkTime = (rule, value, callback) => { // 时间
        if (!value) {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkContent = (rule, value, callback) => { // 内容
        if (!value) {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
     const checkLecturer = (rule, value, callback) => { // 讲师
        if (!value) {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkDuty = (rule, value, callback) => { // 职务
        if (!value) {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkUnit = (rule, value, callback) => { // 单位
        if (!value) {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkClassroom = (rule, value, callback) => { // 教室
        if (!value) {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      const checkLongitude = (rule, value, callback) => { // 经纬度
        if (!value) {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      }
      return {
        fileList: [],
        isDisable: false,
        BMap: null, // 地图组件是否就绪
        showMapComponent: this.dialogVisible,
        keyword: '', // 地区搜索的信息
        // 坐标列表数据
        authenticateList: [],
        mapStyle: {
          width: '100%',
          height: this.mapHeight + 'px'
        },
        center: { lng: 109.126747, lat: 21.486904 },
        zoom: 19,
        curriculumDialogVisible: false,
        curriculumData: [],
        curriculumFrom: {
          courseName: '',
          value2: '',
          value3: '',
          content: '',
          lecturer: '',
          duty: '',
          unit: '',
          classroom: '',
          longitude: '',
          latitude: '',
          type: '',
          isAssess: ''
        },
        lateData: 0,
        signStartData: 0,
        typeData: [
          { title: '需要打卡', value: '0' },
          { title: '不用打卡', value: '1' }
        ],
        rules3: {
          value2: [{
            required: true,
            validator: checkDate,
            trigger: 'blur'
          }],
          value3: [{
            required: true,
            validator: checkTime,
            trigger: 'blur'
          }],
          content: [{
            required: true,
            validator: checkContent,
            trigger: 'blur'
          }],
          lecturer: [{
            required: true,
            validator: checkLecturer,
            trigger: 'blur'
          }],
          duty: [{
            required: true,
            validator: checkDuty,
            trigger: 'blur'
          }],
          unit: [{
            required: true,
            validator: checkUnit,
            trigger: 'blur'
          }],
          classroom: [{
            required: true,
            validator: checkClassroom,
            trigger: 'blur'
          }],
          longitude: [{
            required: true,
            validator: checkLongitude,
            trigger: 'blur'
          }],
          pointValue: [{
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }],
          type: [{
            required: true,
            message: '不能为空',
            trigger: 'change'
          }],
          isAssess: [{
            required: true,
            message: '不能为空',
            trigger: 'change'
          }]
        },
        pageNum: 1,
        pageSize: 10,
        total: 0,
        form: {
          latitude: 21.43527,
          longitude: 109.191269
        },
        showMap: false,
        showclose: false,
        needShow: false
      }
    },
    mounted() {
       this.Config()
       this.curriculum()
    },
    methods: {
      ...mapMutations({
        saveUser: 'SAVE_USER'
      }),
      // 赋值默认值
      changeType(curriculumFrom) {
        if (curriculumFrom === 0) {
          this.needShow = true
        } else {
          this.needShow = false
        }
        console.log(curriculumFrom)
      },
      // 获取需要打卡时间默认值
      Config() {
        getConfig().then(res => {
          this.curriculumFrom.late = res.data.data.late
          this.curriculumFrom.signStart = res.data.data.signStart
          this.lateData = res.data.data.late
          this.signStartData = res.data.data.signStart
          console.log(res.data.data)
        })
      },
      getPlace (e) {
        this.longitudeLatitude = e
        this.$set(this.curriculumFrom, 'longitude', e.lng)
        this.$set(this.curriculumFrom, 'latitude', e.lat)
      },
      confirmLocation() {
        const e = this.longitudeLatitude
        this.stadiumData.latitude = e.lat
        this.stadiumData.longitude = e.lng
        this.showMap = false
      },
      /**
       *日期时间改变
      * **/
      datetimeChange(e) {
          const _this = this
          _this.curriculumFrom.value2 = e
          _this.$forceUpdate()
      },
      // 返回上一个页面
      go() {
         this.$router.go(-1)
      },
      handleSizeChange(e) {
        this.pageSize = e
        this.curriculum()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.curriculum()
      },
      // 列表信息
       curriculum() {
          const query = {
            trainId: this.$route.query.id,
            pageNum: this.pageNum,
            pageSize: this.pageSize
          }
          curriculumList(query).then(resp => {
            if (resp.data.code === '0') {
                this.total = resp.data.data.total // 查询总条数
                this.curriculumData = resp.data.data.rows // 接收返回的数据
             }
          })
        },
      addBt() {
        this.getAuthenticateList()
        this.curriculumFrom = {
          courseName: '',
          value2: '',
          value3: '',
          content: '',
          lecturer: '',
          duty: '',
          unit: '',
          classroom: '',
          longitude: '',
          latitude: '',
          type: '',
          isAssess: '',
          late: this.lateData,
          signStart: this.signStartData
        }
        this.curriculumDialogVisible = true
        console.log(this.curriculumFrom)
        this.$nextTick(() => {
            this.$refs.child.init()
        })
         console.log(this.curriculumFrom.id)
      },
      reset() {
        this.needShow = false
        this.curriculumDialogVisible = false
        this.$refs.curriculumFrom.resetFields()
        this.$refs.child.clearable()
      },
      // 新增
      Add(curriculumFrom) {
        this.$refs[curriculumFrom].validate((valid) => {
          if (valid) {
             this.curriculumFrom.courseName = this.$route.query.className
            if (this.curriculumFrom.id) {
               const query = {
                  id: this.curriculumFrom.id,
                  courseName: this.curriculumFrom.courseName,
                  startTime: this.curriculumFrom.value2 + this.curriculumFrom.value3[0],
                  endTime: this.curriculumFrom.value2 + this.curriculumFrom.value3[1],
                  content: this.curriculumFrom.content,
                  lecturer: this.curriculumFrom.lecturer,
                  duty: this.curriculumFrom.duty,
                  unit: this.curriculumFrom.unit,
                  classroom: this.curriculumFrom.classroom,
                  longitude: this.curriculumFrom.longitude,
                  coordinateId: this.curriculumFrom.pointValue,
                  latitude: this.curriculumFrom.latitude,
                  type: this.curriculumFrom.type,
                  late: this.curriculumFrom.late,
                  signStart: this.curriculumFrom.signStart,
                  isAssess: this.curriculumFrom.isAssess
              }
              editcurriculum(query).then(resp => {
                if (resp.data.code === '0') {
                  // this[formName] = {}
                  this.$refs[curriculumFrom].resetFields()
                  this.$notify({
                    message: '编辑成功',
                    type: 'success'
                  })
                  this.curriculumDialogVisible = false
                  this.curriculum()
                  this.$nextTick(() => {
                      this.$refs.child.clearable()
                  })
                } else {
                  this.$notify.error({
                    title: '错误',
                    message: resp.data.msg
                  })
                }
              })
            } else {
              const query = {
                  trainId: this.$route.query.id,
                  courseName: this.curriculumFrom.courseName,
                  startTime: this.curriculumFrom.value2 + this.curriculumFrom.value3[0],
                  endTime: this.curriculumFrom.value2 + this.curriculumFrom.value3[1],
                  content: this.curriculumFrom.content,
                  lecturer: this.curriculumFrom.lecturer,
                  duty: this.curriculumFrom.duty,
                  unit: this.curriculumFrom.unit,
                  classroom: this.curriculumFrom.classroom,
                  longitude: this.curriculumFrom.longitude,
                  latitude: this.curriculumFrom.latitude,
                  coordinateId: this.curriculumFrom.pointValue,
                  type: this.curriculumFrom.type,
                  late: this.curriculumFrom.late,
                  signStart: this.curriculumFrom.signStart,
                  isAssess: this.curriculumFrom.isAssess
                }
                curriculumAdd(query).then(resp => {
                    if (resp.data.code === '0') {
                      this.$refs[curriculumFrom].resetFields()
                      this.$notify({
                        message: '新增成功',
                        type: 'success'
                      })
                      this.curriculumDialogVisible = false
                      this.curriculum()
                    } else {
                      this.$notify.error({
                        title: '错误',
                        message: resp.data.msg
                      })
                    }
                })
            }
          }
        })
      },
      // 删除
      removeData(row) {
          this.$confirm('是否删除该条记录?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then((e) => {
            if (e === 'confirm') {
              const ids = row.id
              removecurriculum(ids).then(res => {
                if (res.data.code === '0') {
                  this.$notify({
                    title: '删除成功',
                    type: 'success'
                  })
                  const index = this.curriculumData.indexOf(row)
                  this.curriculumData.splice(index, 1)
                  this.total--
                  this.curriculum()
                } else {
                  this.$notify.error({
                    title: '错误',
                    message: res.data.msg
                  })
                }
              })
            }
          })
      },
      // 修改
      editData(row) {
        this.getAuthenticateList()
        this.curriculumDialogVisible = true
        getcurriculum(row.id).then(res => {
          console.log(res.data.data)
          if (res.data.code === '0') {
            this.curriculumFrom = res.data.data
            this.curriculumFrom.value2 = row.courseDate
            this.curriculumFrom.pointValue = res.data.data.coordinateId
            console.log(this.curriculumFrom.pointValue)
            const that = this
            // this.showMap = true
            this.$set(that.curriculumFrom, 'value3', ['' + row.startTime, '' + row.endTime])
            this.$set(this.form, 'longitude', Number.parseFloat(this.curriculumFrom.longitude))
            this.$set(this.form, 'latitude', Number.parseFloat(this.curriculumFrom.latitude))
          //  this.$refs.TMap.editData()
           if (res.data.data.type === 0) {
             this.needShow = true
           }
          }
          this.$nextTick(() => {
            this.$refs.child.init()
          })
        })
      },
      // 导出课程评估表
      expotData(row) {
        // console.log(row)
        getcurriculum(row.id).then(res => {
          const a = res.data.data
          const data = {
            classId: a.trainId,
            courseId: a.id
          }
          getDownExcel(data).then(resp => {
            console.log(resp)
            const link = document.createElement('a')
            const blob = new Blob([resp.data], { type: 'application/vnd.ms-excel' })
            console.log(blob)
               link.style.display = 'none'
               // 设置连接
               link.href = URL.createObjectURL(blob)
               link.download = '课程评估表'
               document.body.appendChild(link)
               // 模拟点击事件
               link.click()
            }).catch((err) => {
              console.log(err)
            })
          // console.log(a)
        })
      },
      // 导出课程模板
      expot() {
        downLoadTemplate().then(resp => {
          console.log(resp)
          const link = document.createElement('a')
          const blob = new Blob([resp.data], { type: 'application/vnd.ms-excel' })
          link.style.display = 'none'
          // 设置连接
          link.href = URL.createObjectURL(blob)
          link.download = '课程模板'
          document.body.appendChild(link)
          // 模拟点击事件
          link.click()
        }).catch((err) => {
          console.log(err)
          this.$message.error(err)
        })
      },
      // 导入课程模板
      // 上传文件之前的钩子：判断上传文件格式、大小等，若返回false则停止上传
      beforeUpload(file) {
        // 文件类型
        var fileType = file.name.substring(file.name.lastIndexOf('.') + 1)
        // 判断文件格式
        if (!(fileType === 'xls' || fileType === 'xlsx' || fileType === 'doc' || fileType === 'docx' || fileType === 'pdf' || fileType === 'pptx' || fileType === 'ppt')) {
          this.$message.error('上传文件只能是xls/xlsx/pdf/doc/docx/pptx/ppt格式！')
        }
        // 文件大小限制为10M
        const fileLimit = file.size / 1024 / 1024 < 200
        if (!fileLimit) {
          this.$message.error('上传文件大小不超过200！')
        }
        return fileType && fileLimit
      },
      // 自定义上传方法，param是默认参数，可以取得file文件信息，具体信息如下图
      uploadHttpRequest(param) {
        const formData = new FormData() // FormData对象，添加参数只能通过append('key', value)的形式添加
        formData.append('file', param.file) // 添加文件对象 要提交给后台的文件
        const classId = this.$route.query.id
        console.log(formData)
        importTemplateExcel(formData, classId).then(resp => {
          console.log(resp)
          if (resp.data.code === '200' || resp.data.code === '0') {
            this.$message({
              title: '成功',
              message: '上传成功',
              type: 'success'
            })
            this.list = resp.data.data
          } else {
            this.$message.error(resp.data.msg)
          }
        }).catch(err => {
          console.log('失败', err)
          param.onError() // 上传失败的文件会从文件列表中删除
        })
      },
      // 点击上传：手动上传到服务器，此时会触发组件的http-request
      submitUpload() {
        this.$refs.upload.submit()
      },
      // 文件发生改变
      fileChange(file, fileList) {
        if (fileList.length > 0) {
          this.fileList = [fileList[fileList.length - 1]] // 展示最后一次选择的文件
        }
      },
      // 移除选择的文件
      fileRemove(file, fileList) {
        if (fileList.length < 1) {
          this.uploadDisabled = true // 未选择文件则禁用上传按钮
          this.$emit('submitUpload:visible', false) // 直接修改父组件的属性
        }
      },
      // 获取坐标点列表数据
      getAuthenticateList() {
        getAuthenticateList().then(res => {
          this.authenticateList = res.data.data.rows
        })
      },
      slectChange(e) {
        console.log(e)
        const data = this.authenticateList.find(item => item.id === e)
        console.log(data)
        this.curriculumFrom.latitude = data.latitude
        this.curriculumFrom.longitude = data.longitude
      }
    }
  }
</script>

<style>
#atlas{width: 100%;height:200px }
#allmap {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  [v-cloak] {
    display:none !important;
  }
  .bg{margin-top: 10px; padding: 20px; background-color: white;}
  .class{
    width: 100%;
    background-color: white;
    position: relative;
    margin-top: -10px;
  }
  .top{
    width: 100%;
    height: 60px;
    background-color: #e9e9e9;
    display: flex;
    align-items: center;
  }
  .num{
    color: #6f6f75;
  }
  .apply{
    position: absolute;
    left: 30px;
    margin: 0;
  }
  .inp{
    width: 250px;
    position: absolute;
    left: 180px;
    border: none;
  }
  .el-but{
    position: absolute;
    left: 440px;
  }
    .el-but2{ margin-left: 20px;
  }
  .left-tag p{
    margin-right: 30px;
  }
  .pag{position: absolute;right:10px}
  .big div{flex: 1}
  .download{margin:auto; width: 150px; line-height: 31px; display: block; border-radius:4px;
    color: #FFF; background-color: #409EFF; border-color: #409EFF;text-align:center;
    font-size:12px; text-decoration:none; cursor:pointer;}
  .download:hover{color: #FFF; background-color: #70b3f9; border-color: #70b3f9; text-decoration:none; }
  .map {
    width: 300px;
    height: 250px;
  }
</style>
